import React, { ReactElement } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { Layout } from '../components/common';
import { StyledButton } from '../components/common/Buttons';
import { Image } from '../utils/image';

const ForgotPassword = (): ReactElement => (
    <Layout>
        <Container>
            <Row
                noGutters
                style={{
                    backgroundImage: `linear-gradient(to bottom, #fffcf9, #fff8f0)`,
                    padding: `3rem`,
                }}
            >
                <Col
                    lg="6"
                    md="12"
                    style={{
                        paddingLeft: `2rem`,
                        paddingRight: `2rem`,
                        backgroundColor: `#ffffff`,
                    }}
                >
                    <div>
                        <Image src="/images/forgot-password@3x.png" />
                        <p style={{ fontSize: `0.8rem`, color: `#535b6c` }}>
                            WorkForce / Employee Identity management - Useful
                            Unified view to view all employees and assign them
                            into different
                        </p>
                    </div>
                </Col>
                <Col
                    lg="6"
                    md="12"
                    style={{
                        paddingLeft: `2rem`,
                        paddingRight: `2rem`,
                        backgroundColor: `#f9f9f9`,
                    }}
                >
                    <div>
                        <div
                            style={{
                                textAlign: `center`,
                                color: `#2382b6`,
                                marginTop: `2rem`,
                                marginBottom: `4rem`,
                            }}
                        >
                            <h5>Forgot Password</h5>
                        </div>
                        <Form>
                            <Form.Group
                                style={{ paddingBottom: `3rem` }}
                                controlId="formBasicEmail"
                            >
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control
                                    style={{ borderRadius: `1rem` }}
                                    type="email"
                                    placeholder="Enter email"
                                />
                            </Form.Group>

                            <Form.Group controlId="formBasicCheckbox">
                                <Form.Text
                                    style={{
                                        textAlign: `center`,
                                        fontSize: `0.8rem`,
                                        color: `#323232`,
                                    }}
                                >
                                    {`Don't have an account?   `}
                                    <a style={{ color: `#2382b6` }} href="#">
                                        Sign Up
                                    </a>
                                </Form.Text>
                            </Form.Group>
                            <div
                                style={{
                                    textAlign: `center`,
                                    paddingTop: `1rem`,
                                }}
                            >
                                <StyledButton type="submit">Send</StyledButton>
                            </div>
                        </Form>
                    </div>
                </Col>
            </Row>
        </Container>
    </Layout>
);

export default ForgotPassword;
